<template>
  <div class="customer-manage">
    <div v-if="!see">
      <div v-if="list.length>0">
        <customer-manage-cell v-for="(item,index) in this.list" v-bind:key="item.id" :data="item" :index="index" :see="!see"
                              @editList="editList" @deleteList="deleteList"/>
      </div>
      <div v-else>
        <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
      </div>
    </div>
    <div v-else>
      <van-tabs  v-model="active" @click="onClick" sticky animated>

        <van-tab title="待处理记录" name="a" style="overflow-y: auto;">
          <!-- 搜索框 -->
          <van-row>
            <van-col span="24">
              <div class="name">
                <van-search v-model="searchFzr" placeholder="请输入负责人"/>
              </div>
            </van-col>
          </van-row>

          <!-- 全选框,复制，当前查询条数 -->
          <div class="baseInfo">
            <van-row>
              <van-col offset="1" span="20">
                <van-radio-group v-model="type" direction="horizontal" shape="square">
                  <van-radio name="00" class="radio0">放弃客户</van-radio>
                  <van-radio name="01" class="radio1">移交客户</van-radio>
                  <van-radio name="02" class="radio2">认领客户</van-radio>
                </van-radio-group>
              </van-col>
            </van-row>
          </div>

          <div v-if="list.length>0">
            <customer-manage-cell v-for="(item,index) in this.list" v-bind:key="item.id" :data="item" :index="index" :see="!see"
                                  @editList="editList" @deleteList="deleteList"/>
          </div>
          <div v-else>
            <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
          </div>
        </van-tab>
        <van-tab title="已处理记录" name="b" style="overflow-y: auto;">
          <!-- 搜索框 -->
          <van-row>
            <van-col span="24">
              <div class="name">
                <van-search v-model="searchFzr1" placeholder="请输入负责人"/>
              </div>
            </van-col>
          </van-row>

          <!-- 全选框,复制，当前查询条数 -->
          <div class="baseInfo">
            <van-row>
              <van-col offset="1" span="20">
                <van-radio-group v-model="type1" direction="horizontal" shape="square">
                  <van-radio name="Y" class="radio2">同意</van-radio>
                  <van-radio name="N" class="radio0">退回</van-radio>
                </van-radio-group>
              </van-col>
            </van-row>
          </div>

          <div v-if="list1.length>0">
            <customer-manage-cell1 v-for="(item,index) in this.list1" v-bind:key="item.id" :data="item" :index="index" :see="!see"
                                  @editList="editList" @deleteList="deleteList"/>
          </div>
          <div v-else>
            <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
          </div>
        </van-tab>
      </van-tabs>

    </div>


  </div>
</template>

<script>

import {Toast} from "vant";
import CustomerManageCell from "../../components/CustomerManageCell";
import CustomerManageCell1 from "../../components/CustomerManageCell1";
import {getCustomerManage, getCustomerManage1, returnCustomerManage, agreeGpCustomerManage} from "../../api/customerManage";

export default {
  name: "CustomerManage",
  components: {CustomerManageCell, CustomerManageCell1},
  mounted() {
    if (this.$route.params.id == '0') {
      this.see = true
    }
    this.init()
    this.init1()
  },
  watch: {
    'type': function () {
      this.list = []
      getCustomerManage({
        id: '',
        type: this.type,
        fzr: this.$store.getters.userId,
        fzrName: this.searchFzr
      }).then(res => {
        this.list = res
      }).catch(err => {
        console.log(err)
      });
    },
    'type1': function () {
      this.list1 = []
      getCustomerManage1({
        type: this.type1,
        fzrName: this.searchFzr1
      }).then(res => {
        this.list1 = res
      }).catch(err => {
        console.log(err)
      });
    },
    'searchFzr': function () {
      this.list = []
      getCustomerManage({
        id: '',
        type: this.type,
        fzr: this.$store.getters.userId,
        fzrName: this.searchFzr
      }).then(res => {
        this.list = res
      }).catch(err => {
        console.log(err)
      });
    },
    'searchFzr1': function () {
      this.list1 = []
      getCustomerManage1({
        type: this.type1,
        fzrName: this.searchFzr1
      }).then(res => {
        this.list1 = res
      }).catch(err => {
        console.log(err)
      });
    }
  },
  data() {
    return {
      searchFzr: '',
      searchFzr1: '',
      list: [],
      list1: [],
      type: '',
      type1: '',
      see: false,
      active: 0,
    }
  },
  methods: {
    init() {
      this.list = []
      if (this.$route.params.id == '0') {
        getCustomerManage({
          id: '',
          type: this.type,
          fzr: this.$store.getters.userId,
          fzrName: this.searchFzr
        }).then(res => {
          this.list = res
        }).catch(err => {
          console.log(err)
        });
      } else {
        getCustomerManage({
          id: this.$route.params.id,
          type: this.type,
          fzr: this.$store.getters.userId,
          fzrName: this.searchFzr
        }).then(res => {
          this.list = res
        }).catch(err => {
          console.log(err)
        });
      }
    },

    init1() {
      this.list1 = []
      getCustomerManage1({
        type: this.type1,
        fzrName: this.searchFzr1
      }).then(res => {
        this.list1 = res
      }).catch(err => {
        console.log(err)
      });
    },

    editList(data) {
      console.log(data)
      Toast.loading({
        message: '操作中...请等待',
        forbidClick: true,
        loadingType: 'spinner'
      });
        agreeGpCustomerManage({
          id: data.id,
          crmCustomerid: data.crmCustomerid,
          type: data.type == '放弃客户' ? '00' : data.type == '转移客户' ? '01' : '02',
          fzr: data.fzr,
          customerName: data.customerName,
          tranFzr: data.tranFzr,
          createdPeople: data.createdPeople,
          modifiedPeople: this.$store.getters.userId,
        }).then(res => {
          Toast.success('操作成功！已推送对应负责人');
          this.init()
        }).catch(err => {
          console.log(err)
        });
    },
    deleteList(data) {
      console.log(data)
      Toast.loading({
        message: '退回中...请等待',
        forbidClick: true,
        loadingType: 'spinner'
      });
      returnCustomerManage({
        id: data.id,
        modifiedPeople: this.$store.getters.userId,
      }).then(res => {
        Toast.success('退回成功！已推送对应负责人');
        this.init()
      }).catch(err => {
        console.log(err)
      });

    },

    onClick(){
      this.init()
      this.init1()
    }
  }
}
</script>

<style scoped>
.customer-manage {
  background: #FFFFFF;
}

.customer-manage .baseInfo {
  font-size: 12px;
  color: #BAB8B7;
  margin-top: 0px;
  padding: 10px;
  background: #FFFFFF;
}

</style>

<style>

.customer-manage .radio0 .van-radio__label {
  margin-left: 8px;
  line-height: 20px;
  color: red;
}

.customer-manage .radio1 .van-radio__label {
  margin-left: 8px;
  line-height: 20px;
  color: #FD843E;
}

.customer-manage .radio2 .van-radio__label {
  margin-left: 8px;
  line-height: 20px;
  color: #07C160;
}
</style>