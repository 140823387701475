<template>
  <div :class="data.type=='放弃客户'?'customer-manage-cell':data.type=='转移客户'?'pesonal-cell1':'pesonal-cell2'">
      <van-cell-group class="group-cell">
        <van-row>
          <van-col offset="1" span="15" class="text van-ellipsis info"
                   style=" text-align: left; font-size: 15px; font-weight: bold;">
            {{ data.customerName }}
          </van-col>
          <van-col offset="4" span="4">
            <van-icon size="40"
                      :name="data.handle=='Y' ? require('@/assets/同意.png') : require('@/assets/退回.png') "/>
          </van-col>
        </van-row>

        <van-divider contentPosition="center" class="driver"></van-divider>

        <van-row>
          <van-col span="23" offset="1" class="contain-detail">
            原因：{{ data.reason }}
          </van-col>
        </van-row>

        <van-row>
          <van-col span="23" offset="1" class="contain-detail">
            申请日期：{{ data.creTime }}
          </van-col>
        </van-row>

        <van-row v-if="data.type=='转移客户'">
          <van-col span="15" offset="1" class="contain-fzr">
            新负责人：{{ data.tranFzr }}
          </van-col>
        </van-row>

        <van-divider contentPosition="center" class="driver"></van-divider>

        <van-row>
          <van-col v-if="data.tranFzr" span="15" offset="1" class="contain-fzr">
            原负责人：{{ data.fzr }}
          </van-col>
          <van-col v-else span="15" offset="1" class="contain-fzr">
            负责人：{{ data.fzr }}
          </van-col>
        </van-row>

        <van-divider contentPosition="center" class="driver"></van-divider>
        <van-row>
          <van-col span="15" offset="1" class="contain-fzr">
            处理人：{{ data.handlePer }}
          </van-col>
        </van-row>
        <van-row>
          <van-col span="15" offset="1" class="contain-fzr">
            处理时间：{{ data.handleTime }}
          </van-col>
        </van-row>

        <van-row>
          <van-col span="15" offset="1" class="contain-fzr">

          </van-col>
          <van-col span="5" offset="3" class="contain-fzr"
                   :style="{color: data.type=='放弃客户'?'red':data.type=='转移客户'?'#FD843E':'#07C160'}">
            {{ data.type }}
          </van-col>
        </van-row>
      </van-cell-group>
  </div>
</template>

<script>
import '@vant/touch-emulator';
export default {
  name: "PersonalCell",
  filters: {
    // 过滤器filter,不允许超过10个长度
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 4) {
        return value.slice(0, 4) + '...'
      }
      return value
    }
  },
  methods: {
    deleteList(data) {
      this.$emit('deleteList', data)
    },
    editList(data) {
      console.log(data)
      data.indexNew = this.index
      this.$emit('editList', data)
    },
  },
  props: {
    data: Object,
    index: Number,
    id: String,
    see: Boolean
  }
}
</script>

<style scoped>
.customer-manage-cell .group-cell .van-cell__title {
  min-width: 55%;
}

.customer-manage-cell .group-cell {
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  padding: 10px;
}



.group-cell .van-cell__title {
  min-width: 55%;
}

.group-cell {
  /*color: #323233;*/
  font-size: 14px;
  line-height: 24px;
  padding: 2px;
}

.group-cell .customer-manage-cell-label {
  margin-top: 4px;
  color: #969799;
  font-size: 12px;
  line-height: 18px;
}

.group-cell .customer-manage-cell-label {
  margin-top: 4px;
  color: #969799;
  font-size: 12px;
  line-height: 18px;
}

.prompt {
  font-size: 15px;
  color: red;
  margin-top: 5px
}

.customer-manage-cell {
  box-shadow: 1px 1px 5px red;
  border-radius: 5px;
  margin: 17px 10px;
  padding: 5px;
}

.pesonal-cell1 {
  box-shadow: 1px 1px 5px #FD843E;
  border-radius: 5px;
  margin: 17px 10px;
  padding: 5px;
}

.pesonal-cell2 {
  box-shadow: 1px 1px 5px #07C160;
  border-radius: 5px;
  margin: 17px 10px;
  padding: 5px;
}

.contain-detail {
  text-align: left;
  font-size: 14px;
  font-family: 微软雅黑;
}

.driver {
  background-color: #473333;
  margin: 10px 11px 6px 4%;
}

.contain-fzr {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  font-family: 微软雅黑;
  margin-top: 5px;
}

</style>