import request from '@/utils/request'

export function getCustomerManage(formFile) {
    return request({
        url: "/api/ajax/bulkCrmsCusList/getCustomerManage.json",
        method: 'post',
        data: formFile,
    })
}

export function getCustomerManage1(formFile) {
    return request({
        url: "/api/ajax/bulkCrmsCusList/getCustomerManage1.json",
        method: 'post',
        data: formFile,
    })
}

export function returnCustomerManage(formFile) {
    return request({
        url: "/api/ajax/bulkCrmsCusList/returnCustomerManage.json",
        method: 'post',
        data: formFile,
    })
}

export function agreeGpCustomerManage(formFile) {
    return request({
        url: "/api/ajax/bulkCrmsCusList/agreeGpCustomerManage.json",
        method: 'post',
        data: formFile,
    })
}





